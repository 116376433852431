import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import RoomPlanner1 from '../views/room-planner-1/Index'
// import RoomPlanner2 from '../views/room-planner-2/Index'
import RoomPlanner3 from '../views/room-planner-3/Index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: RoomPlanner3
  }
  // Other prototypes not needed any more
  // {
  //   path: '/room-planner-1',
  //   name: 'room-planner-1',
  //   component: RoomPlanner1
  // },
  // {
  //   path: '/room-planner-2',
  //   name: 'room-planner-2',
  //   component: RoomPlanner2
  // },
  // {
  //   path: '/room-planner-3',
  //   name: 'room-planner-3',
  //   component: RoomPlanner3
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
