import {
  ROOMPLAN
} from '@/store/mutation-types'

function saveHistory (state) {
  // cut newer history
  if (state.historyStep < state.history.length - 1) {
    state.history.splice(state.historyStep + 1, state.history.length)
  }
  state.history.push(JSON.parse(JSON.stringify(state.elements)))
  state.historyStep += 1
}

function dist (p1, p2) {
  return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2)
}

function isPointOnLine (p0, [p1, p2]) {
  // I think that function is not correct for all cases
  const diff = Math.abs(dist(p0, p1) + dist(p0, p2) - dist(p1, p2))
  return diff < 0.000001
}

function isLineOneLine (smallLine, largeLine) {
  return isPointOnLine(smallLine[0], largeLine) && isPointOnLine(smallLine[1], largeLine)
}

// from here: https://jsfiddle.net/shishirraven/4dmjh0sa/
function findClosestPointOnLine (p, line) {
  const [a, b] = line
  const atob = { x: b.x - a.x, y: b.y - a.y }
  const atop = { x: p.x - a.x, y: p.y - a.y }
  const len = atob.x * atob.x + atob.y * atob.y
  const dot = atop.x * atob.x + atop.y * atob.y
  const t = Math.min(1, Math.max(0, dot / len))
  // const ddot = ( b.x - a.x ) * ( p.y - a.y ) - ( b.y - a.y ) * ( p.x - a.x );
  return {
    x: a.x + atob.x * t,
    y: a.y + atob.y * t
  }
}

function getAttachedObjects (elements, wall) {
  const attached = []
  elements.forEach(otherElement => {
    if (otherElement === wall) {
      return false
    }
    // don't check walls
    if (otherElement.type === 'wall') {
      return false
    }
    for (let i = 0; i < wall.segments.length - 1; i++) {
      const segment = [wall.segments[i], wall.segments[i + 1]]
      if (isLineOneLine(otherElement.segments, segment)) {
        attached.push({ element: otherElement, indexSegment: i, delta: dist(segment[0], otherElement.segments[0]) / dist(segment[0], segment[1]) })
      }
    }
  })
  return attached
}

export default {
  [ROOMPLAN.CURSOR] (state, data) {
    state.cursor = data
  },
  [ROOMPLAN.CURSORS.DEFAULT] (state) {
    state.cursor = null
  },
  [ROOMPLAN.CURSORS.POINTER] (state) {
    state.cursor = 'pointer'
  },
  [ROOMPLAN.CURSORS.GRAB] (state) {
    state.cursor = 'grab'
  },
  [ROOMPLAN.CURSORS.GRABBING] (state) {
    state.cursor = 'grabbing'
  },

  // [ROOMPLAN.ZOOM.IN] (state) {
  //   state.zoom *= 1.1
  // },
  // [ROOMPLAN.ZOOM.OUT] (state) {
  //   state.zoom /= 1.1
  // },

  [ROOMPLAN.DRAWING.TOGGLE] (state, data) {
    if (state.drawingMode === data) {
      state.drawingMode = null
    } else {
      state.drawingMode = data
    }
  },

  [ROOMPLAN.ELEMENTS.ADD] (state, data) {
    state.elements.push(data)
    saveHistory(state)
  },
  [ROOMPLAN.ELEMENTS.DELETE] (state, indexToRemove) {
    if (indexToRemove === undefined) {
      indexToRemove = state.selectedElementIndex
    }
    const element = state.elements[indexToRemove]
    if (state.selectedSegmentIndex === 0) {
      element.segments.splice(state.selectedSegmentIndex, 1)
    } else if (state.selectedSegmentIndex === element.segments.length - 2) {
      element.segments.splice(state.selectedSegmentIndex + 1, 1)
    } else if (state.selectedSegmentIndex) {
      const el1 = JSON.parse(JSON.stringify(element))
      const el2 = JSON.parse(JSON.stringify(element))
      el1.segments = el1.segments.slice(0, state.selectedSegmentIndex + 1)
      el2.segments = el2.segments.slice(state.selectedSegmentIndex + 1, el2.segments.length)
      state.elements.splice(indexToRemove, 1, el1, el2)
    } else {
      state.elements.splice(indexToRemove, 1)
    }
    // remove element
    state.selectedElementIndex = null
    state.selectedSegmentIndex = null
  },
  [ROOMPLAN.ELEMENTS.UPDATE] (state, data) {
    Object.assign(state.elements[data.index], data.config)
    saveHistory(state)
  },
  [ROOMPLAN.ELEMENTS.SNAP] (state, data) {
    const element = state.elements[data.index]
    const center = {
      x: (element.segments[0].x + element.segments[1].x) / 2,
      y: (element.segments[0].y + element.segments[1].y) / 2
    }
    const elementDist = dist(element.segments[0], element.segments[1])
    state.elements.forEach(otherElement => {
      if (otherElement.type !== 'wall') {
        return
      }
      for (let i = 0; i < otherElement.segments.length - 1; i++) {
        const segment = [otherElement.segments[i], otherElement.segments[i + 1]]
        const closest = findClosestPointOnLine(center, segment)
        const isClose = dist(closest, center) < 20
        if (!isClose) {
          return
        }
        const centerDelta = dist(segment[0], closest) / dist(segment[0], segment[1])
        const halfDelta = elementDist / 2 / dist(segment[0], segment[1])
        const firstDelta = centerDelta - halfDelta
        const secondDelta = centerDelta + halfDelta

        element.segments = [{
          x: segment[0].x * (1 - firstDelta) + segment[1].x * firstDelta,
          y: segment[0].y * (1 - firstDelta) + segment[1].y * firstDelta
        }, {
          x: segment[0].x * (1 - secondDelta) + segment[1].x * secondDelta,
          y: segment[0].y * (1 - secondDelta) + segment[1].y * secondDelta
        }]
      }
    })
    saveHistory(state)
  },
  [ROOMPLAN.ELEMENTS.SELECT] (state, data) {
    state.selectedElementIndex = data?.elementIndex ?? null
    state.selectedSegmentIndex = data?.segmentIndex ?? null
  },
  [ROOMPLAN.WALLSEGMENT.ADD] (state, data) {
    state.elements[data.indexWall].segments.splice(data.indexSegment, 0, data.point)
  },
  [ROOMPLAN.WALLSEGMENT.UPDATE] (state, data) {
    const wall = state.elements[data.indexWall]
    const attached = getAttachedObjects(state.elements, wall)
    Object.assign(wall.segments[data.indexSegment], data.config)
    attached.forEach(({ element, indexSegment, delta }) => {
      const moved = indexSegment === data.indexSegment || indexSegment + 1 === data.indexSegment
      if (!moved) {
        return
      }

      // get width of door/window
      const width = dist(element.segments[0], element.segments[1])

      // now we need to fit it into a new position of wall:
      const wallSegment = [wall.segments[indexSegment], wall.segments[indexSegment + 1]]
      const wallWidth = dist(wallSegment[0], wallSegment[1])
      const partOfWall = width / wallWidth

      // delta = Math.min(delta, 1 - delta)

      const secondDelta = delta + partOfWall

      const segment = [{
        x: wallSegment[0].x * (1 - delta) + wallSegment[1].x * delta,
        y: wallSegment[0].y * (1 - delta) + wallSegment[1].y * delta
      }, {
        x: wallSegment[0].x * (1 - secondDelta) + wallSegment[1].x * secondDelta,
        y: wallSegment[0].y * (1 - secondDelta) + wallSegment[1].y * secondDelta
      }]

      element.segments = segment
    })
    // now we need to update all attached objects
    // should we have "attachedTo" property?
  },
  [ROOMPLAN.WALLSEGMENT.DELETE] (state, data) {
    state.elements[data.wallIndex].segments.splice(data.segmentIndex, 1)
  },
  [ROOMPLAN.HISTORY.SAVE] (state) {
    saveHistory(state)
  },
  [ROOMPLAN.HISTORY.UNDO] (state) {
    if (state.historyStep === 0) {
      console.log('no undo action')
      return
    }

    state.historyStep -= 1
    state.elements = state.history[state.historyStep]
  },
  [ROOMPLAN.HISTORY.REDO] (state) {
    if (state.historyStep === state.history.length - 1) {
      console.log('no redo action')
      return
    }
    state.historyStep += 1
    state.elements = state.history[state.historyStep]
  }
}
