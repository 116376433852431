<template>
  <v-group :config="group" @click="handleClick">
    <!-- <v-line :config="configBottom"/> -->
    <!-- <v-rect :config="configRect"/> -->
    <v-line :config="configLine"/>
    <v-line :config="configLine2"/>
    <v-group v-if="measuresEnabled" :config="configMeasureGroup">
      <v-line :config="configMeasureStart" />
      <v-line :config="configMeasureEnd" />
      <v-line :config="configMeasureLine" />
      <v-rect :config="configMeasureTextBackground" />
      <v-text :config="configMeasureText" />
    </v-group>
    <!--    <v-circle :config="circle"/>-->
    <!-- <v-circle :config="cornerCircle"/> -->
    <!-- <v-circle :config="anchorCircle"/> -->
  </v-group>
</template>
<script>
import WALLCONSTANTS from './constants'
import { mapState } from 'vuex'
import {
  ROOMPLAN
} from '@/store/mutation-types'

const pixelsToMillimeters = (px) => {
  return (px * 10).toFixed(0)
}

const MEASURE_OFFSET = 40

export default {
  props: {
    indexWall: {
      required: true,
      type: Number
    },
    indexSegment: {
      required: true,
      type: Number
    },
    measuresEnabled: Boolean
  },
  data () {
    return {
      lineDefaults: {
        stroke: WALLCONSTANTS.COLOURS.PINK,
        strokeWidth: WALLCONSTANTS.STROKEWIDTH,
        lineCap: 'round'
      },
      rectDefaults: {
        strokeWidth: WALLCONSTANTS.STROKEWIDTH,
        cornerRadius: WALLCONSTANTS.BORDERRADIUS,
        stroke: WALLCONSTANTS.COLOURS.PINK,
        fill: WALLCONSTANTS.COLOURS.PINK,
        lineCap: 'round'
      },
      circle: {
        radius: 5,
        x: 0,
        y: 0,
        fill: WALLCONSTANTS.COLOURS.PINK
      }
    }
  },
  computed: {
    ...mapState({
      wall (state) { return state.roomPlan.elements[this.indexWall] }
    }),
    // The wall and corner rendered
    corner () {
      return this.wall.segments[this.indexSegment]
    },
    group () {
      return {
        listening: !this.wall.isDrawing
        // opacity: 0.5
        // x: this.corner.x,
        // y: this.corner.y
      }
    },
    // The anchor corner
    anchor () {
      // if (this.corner.connect) {
      //   // Closed loop
      //   if (this.indexSegment === 0) {
      //     return this.wall.segments[this.wall.segments.length - 1]
      //   }
      //   return this.wall.segments[this.indexSegment - 1]
      // }
      if (this.indexSegment < this.wall.segments.length - 1) {
        return this.wall.segments[this.indexSegment + 1]
      }

      return this.wall.segments[this.indexSegment]
    },
    length () {
      const x = this.anchor.x - this.corner.x
      const y = this.anchor.y - this.corner.y
      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
    },
    angle () {
      return this.radians * 180 / Math.PI
    },
    radians () {
      const PI2 = Math.PI * 2
      const x = this.anchor.x - this.corner.x
      const y = this.anchor.y - this.corner.y
      const radians = (Math.atan2(y, x) + PI2) % PI2
      return radians
    },
    offsetCorner () {
      return {
        x: this.corner.x + MEASURE_OFFSET * Math.cos(this.radians - Math.PI / 2),
        y: this.corner.y + MEASURE_OFFSET * Math.sin(this.radians - Math.PI / 2)
      }
    },
    offsetAnchor () {
      return {
        x: this.anchor.x + MEASURE_OFFSET * Math.cos(this.radians - Math.PI / 2),
        y: this.anchor.y + MEASURE_OFFSET * Math.sin(this.radians - Math.PI / 2)
      }
    },
    cornerCircle () {
      return {
        radius: WALLCONSTANTS.THICKNESS / 2,
        x: 0 + WALLCONSTANTS.THICKNESS / 2,
        y: 0 + WALLCONSTANTS.THICKNESS / 2,
        fill: WALLCONSTANTS.COLOURS.GREY
      }
    },
    anchorCircle () {
      return {
        radius: WALLCONSTANTS.THICKNESS / 2,
        x: this.anchor.x - this.corner.x + WALLCONSTANTS.THICKNESS / 2,
        y: this.anchor.y - this.corner.y + WALLCONSTANTS.THICKNESS / 2,
        fill: WALLCONSTANTS.COLOURS.GREY
      }
    },
    configLine () {
      return {
        points: [this.corner.x, this.corner.y, this.anchor.x, this.anchor.y],
        ...this.lineDefaults
      }
    },
    configLine2 () {
      return {
        points: [this.corner.x, this.corner.y, this.anchor.x, this.anchor.y],
        stroke: WALLCONSTANTS.COLOURS.WHITE,
        strokeWidth: 2,
        lineCap: 'round'
      }
    },

    /*
     * Dimensions/Measurement
     */
    configMeasureGroup () {
      return {
        x: this.offsetCorner.x,
        y: this.offsetCorner.y,
        rotation: this.angle
      }
    },
    configMeasureStart () {
      return {
        points: [0, -20, 0, MEASURE_OFFSET],
        stroke: WALLCONSTANTS.COLOURS.PINK,
        strokeWidth: 1
      }
    },
    configMeasureEnd () {
      return {
        points: [this.length, -20, this.length, MEASURE_OFFSET],
        stroke: WALLCONSTANTS.COLOURS.PINK,
        strokeWidth: 1
      }
    },
    configMeasureLine () {
      return {
        points: [0, 0, this.length, 0],
        stroke: WALLCONSTANTS.COLOURS.PINK,
        strokeWidth: 1,
        fill: WALLCONSTANTS.COLOURS.PINK
      }
    },
    // TODO Text and background will need to hide at some threshold
    configMeasureTextBackground () {
      // TODO How to make the dimensions of this relative to the dimensions of the text
      return {
        width: this.length / 2,
        height: 20,
        y: -11,
        x: this.length / 4,
        fill: WALLCONSTANTS.COLOURS.PINK
      }
    },
    configMeasureText () {
      const reverse = this.angle > 90 && this.angle < 270

      return {
        width: this.length,
        align: 'center',
        fontSize: 14,
        fill: WALLCONSTANTS.COLOURS.WHITE,
        y: reverse ? 14 - 5 : -10,
        text: pixelsToMillimeters(this.length) + ' mm',
        x: reverse ? this.length : 0,
        scaleX: reverse ? -1 : 1,
        scaleY: reverse ? -1 : 1,
        height: 20,
        verticalAlign: 'middle'
      }
    },
    configMeasureArrow () {
      return {
        points: [0, 0, this.length, 0],
        stroke: 'grey',
        strokeWidth: 1,
        pointerAtBeginning: true,
        y: -7,
        text: pixelsToMillimeters(this.length) + ' m',
        fill: WALLCONSTANTS.COLOURS.WHITE
      }
    },

    configBottom () {
      return {
        x: this.corner.x,
        y: this.corner.y,
        points: [0, 0, this.anchor.x - this.corner.x, this.anchor.y - this.corner.y],
        ...this.lineDefaults
      }
    },
    configRect () {
      return {
        x: this.corner.x,
        y: this.corner.y,
        width: this.length + WALLCONSTANTS.THICKNESS,
        height: WALLCONSTANTS.THICKNESS,
        rotation: this.angle,
        offset: {
          x: WALLCONSTANTS.THICKNESS / 2,
          y: WALLCONSTANTS.THICKNESS / 2
        },
        ...this.rectDefaults
      }
    }

    // TODO How to add in the angle with an line arc connecting the 2 segments that the angle relates too.
    // TODO The measurment lines and text should always be on the outside of the box, ie on the side with the largest angle, with the angle measurement always on the side of the smallest angle.
  },
  methods: {
    handleClick (e) {
      this.$store.commit(ROOMPLAN.ELEMENTS.SELECT, { elementIndex: this.indexWall, segmentIndex: this.indexSegment })
    }
  }
}
</script>
