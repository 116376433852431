import Vue from 'vue'
import Vuex from 'vuex'
import roomPlan from './room-plan'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    roomPlan
  }
})
