var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layer',{ref:"grid"},[_vm._l((_vm.smallGridX),function(x){return _c('v-line',{key:'x1-' + x,attrs:{"config":{
    x: (x * _vm.GRIDSIZE / _vm.DIVISIONS) - _vm.transform[4],
    y: -_vm.transform[5],
    points: [0, 0, 0, _vm.config.height / _vm.transform[0]],
    stroke: '#F6F7F9'
  }}})}),_vm._l((_vm.smallGridY),function(y){return _c('v-line',{key:'y1-' + y,attrs:{"config":{
    x: -_vm.transform[4],
    y: (y * _vm.GRIDSIZE / _vm.DIVISIONS) - _vm.transform[5],
    points: [0, 0, _vm.config.width / _vm.transform[0], 0],
    stroke: '#F6F7F9'
  }}})}),_vm._l((_vm.gridX),function(x){return _c('v-line',{key:'x2-' + x,attrs:{"config":{
    x: (x * _vm.GRIDSIZE) - _vm.transform[4],
    y: -_vm.transform[5],
    points: [0, 0, 0, _vm.config.height / _vm.transform[0]],
    stroke: '#E8E8E9'
  }}})}),_vm._l((_vm.gridY),function(y){return _c('v-line',{key:'y2-' + y,attrs:{"config":{
    x: -_vm.transform[4],
    y: (y * _vm.GRIDSIZE) - _vm.transform[5],
    points: [0, 0, _vm.config.width / _vm.transform[0], 0],
    stroke: '#E8E8E9'
  }}})}),_c('v-line',{attrs:{"config":{
    x: 0,
    y: 0,
    points: [0, 0, 0, _vm.config.height],
    stroke: 'red'
  }}}),_c('v-line',{attrs:{"config":{
    x: 0,
    y: 0,
    points: [0, 0, _vm.config.width, 0],
    stroke: 'red'
  }}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }