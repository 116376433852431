export default {
  roomPlan: null,
  deltas: [],
  modal: {
    visible: false,
    x: 0,
    y: 0,
    title: null,
    component: null
  },
  history: [[]],
  historyStep: 0,
  drawingMode: null,
  selectedElementIndex: null,
  selectedSegmentIndex: null,
  elements: [],
  cursor: null,
  catalogue: {
    doors: [],
    windows: [],
    cupboards: [],
    appliances: []
  }
}
