<template>
  <v-group>
    <component v-for="(element, i) of elements" :key="'element-' + i" :is="element.type" :index-element="i"/>
  </v-group>
</template>
<script>

import Wall from '@/views/room-planner-3/components/walls/Wall'
import Door from '@/views/room-planner-3/components/doors/Door'
import Window from '@/views/room-planner-3/components/windows/Window'
import Box from '@/views/room-planner-3/components/catalogue/Box'
import SvgKonva from '@/views/room-planner-3/components/catalogue/SvgKonva'
import Image from '@/views/room-planner-3/components/image/Image'

import { mapState } from 'vuex'

export default {
  components: {
    Wall,
    Door,
    Window,
    Box,
    SvgKonva,
    Stove: Image
  },
  computed: {
    ...mapState({
      elements: state => state.roomPlan.elements
    })
  }
}
</script>
