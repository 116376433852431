<template>
  <div id="room-planner">
    <div id="stage" :class="stageClass" tabindex="1" @keydown="handleKeyDown" @dragover.prevent @drop.prevent="dropStage">
      <v-stage ref="stage" :config="stageConfig" @wheel="wheel" @click="handleClick" @tap="handleClick" @mousemove="handleMouseMove">
        <grid :config="gridConfig"/>
        <v-layer ref="drawing">
          <elements/>
          <v-rect
              v-for="item in rectangles"
              :key="item.id"
              :config="item"
              @transformend="handleTransformEnd"
              @dragmove="dragMove"
              @dragend="dragEnd"
          />
          <v-transformer ref="tr" />
        </v-layer>
      </v-stage>
    </div>
    <div id="actions">
      <button class="btn btn-outline-primary" :class="{ active: drawingMode === 'wall'}" @click="createWall">Wall+</button>
      <button class="btn btn-outline-primary" :class="{ active: drawingMode === 'door'}" @click="createDoor">Door+</button>
      <button class="btn btn-outline-primary" :class="{ active: drawingMode === 'window'}" @click="createWindow">Window+</button>
      <button class="btn btn-outline-primary" :class="{ active: drawingMode === 'stove'}" @click="createStove">Stove+</button>
      <button id="boxes" class="btn btn-outline-primary" :class="{ active: drawingMode === 'boxes'}" @click="$root.$emit('bv::hide::popover')">Box+</button>
      <button id="svgs" class="btn btn-outline-primary" :class="{ active: drawingMode === 'svgs'}" @click="$root.$emit('bv::hide::popover')">SVG+</button>
<!--      <button id="catalogue" class="btn btn-outline-primary" :class="{ active: drawingMode === 'catalogue'}" @click="toggleCatalogue">Catalogue+</button>-->
    </div>
    <div id="controls">
      <button class="btn btn-outline-primary" @click="zoomIn">Zom in</button>
      <button class="btn btn-outline-primary" @click="zoomOut">Zoom out</button>
      <button class="btn btn-outline-primary" @click="undo" :disabled="!hasUndo">Undo</button>
      <button class="btn btn-outline-primary" @click="redo" :disabled="!hasRedo">Redo</button>
    </div>
    <element-modal v-if="selectedElementIndex !== null" />
    <b-popover target="boxes" placement="right" title="Boxes" trigger="click"><boxes @boxes-drag-start="dragStart($event)"/></b-popover>
    <b-popover target="svgs" placement="right" title="Appliances" trigger="click"><svgs @svg-drag-start="dragStart($event)"/></b-popover>
    <b-popover target="catalogue" placement="right" trigger="click"><catalogue ref="catalogue" @catalogue-drag-start="dragStart"/></b-popover>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Grid from '@/views/room-planner-3/components/Grid'
import Elements from '@/views/room-planner-3/components/Elements'
import ElementModal from '@/views/room-planner-3/components/ElementModal'

import Boxes from '@/views/room-planner-3/components/catalogue/Boxes'
import Svgs from '@/views/room-planner-3/components/catalogue/Svgs'
import Catalogue from '@/views/room-planner-3/components/catalogue/Index'
import { BPopover } from 'bootstrap-vue'

import { ROOMPLAN } from '@/store/mutation-types'
import WallConstants from '@/views/room-planner-3/components/walls/constants'

import ObjectSnap from '@/views/room-planner-3/mixins/ObjectSnap'
// import Transformer from '@/views/room-planner-3/mixins/Transformer'

// const MARGINBOTTOM = 48
const MARGIN = 16

// this function will return pointer position relative to the passed node
function getRelativePointerPosition (node) {
  var transform = node.getAbsoluteTransform().copy()
  // to detect relative position we need to invert transform
  transform.invert()

  // get pointer (say mouse or touch) position
  var pos = node.getStage().getPointerPosition()

  // now we can find relative point
  return transform.point(pos)
}

// const INSET = 10
//
// const dragBoundFunc = function (pos) {
//   const xOuter = this.parent.parent.width() - INSET - this.width()
//   if (pos.x < INSET) {
//     pos.x = INSET
//   } else if (pos.x > xOuter) {
//     pos.x = xOuter
//   }
//
//   const yOuter = this.parent.parent.height() - INSET - this.height()
//   if (pos.y < INSET) {
//     pos.y = INSET
//   } else if (pos.y > yOuter) {
//     pos.y = yOuter
//   }
//   return pos
// }

export default {
  props: {
    section: {
      default: 'dimensions'
    }
  },
  components: {
    Grid,
    Elements,
    ElementModal,
    BPopover,
    Boxes,
    Svgs,
    Catalogue
  },
  mixins: [ObjectSnap],
  computed: {
    stage () {
      return this.$refs.stage.getNode()
    },
    ...mapState({
      stageClass: state => {
        const classes = []
        if (state.roomPlan.cursor !== null) {
          classes.push(`cursor-${state.roomPlan.cursor}`)
        }
        return classes.join(' ')
      },
      selectedElementIndex (state) {
        return state.roomPlan.selectedElementIndex
      },
      drawingWall: (state) => {
        return state.roomPlan.drawingMode === 'wall'
      },
      drawingMode: (state) => {
        return state.roomPlan.drawingMode
      },
      hasUndo: (state) => {
        return state.roomPlan.historyStep > 0
      },
      hasRedo: (state) => {
        return state.roomPlan.historyStep < state.roomPlan.history.length - 1
      },
      walls: state => state.roomPlan.elements.filter(e => e.type === 'wall'),
      elements: state => state.roomPlan.elements
    })
  },
  data () {
    return {
      // Paginate,
      stageConfig: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1
      },
      gridConfig: {
        width: 0,
        height: 0,
        x: 0,
        y: 0
      },
      rectangles: []
    }
  },
  watch: {
    selectedElementIndex () {
      const element = this.elements[this.selectedElementIndex]
      const isSelectable = element && (['door', 'window', 'svg-konva'].includes(element.type))

      if (isSelectable) {
        // TODO: find a better way to find an element's node
        const node = this.$refs.drawing.getNode().children[0].children[this.selectedElementIndex]
        console.log(node)
        const transformerNode = this.$refs.tr.getNode()
        transformerNode.nodes([node])
        transformerNode.keepRatio(true)
        transformerNode.rotationSnaps([0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 185, 300, 315, 330, 345])
        transformerNode.rotationSnapTolerance(5)
      } else {
        this.$refs.tr.getNode().nodes([])
      }
    }
  },
  methods: {
    fitStageIntoParentContainer () {
      const stageContainer = document.getElementById('stage')
      // NOTE Removed nav
      // const nav = document.getElementById('nav')
      const width = stageContainer.offsetWidth
      // const height = window.innerHeight - nav.offsetHeight - MARGINBOTTOM
      const height = window.innerHeight - 2 * MARGIN
      this.stageConfig.width = width
      this.stageConfig.height = height
      this.gridConfig.width = width
      this.gridConfig.height = height
    },
    zoom (direction, fromPointer) {
      const oldScale = this.stage.scaleX()
      const pointer = fromPointer ? this.stage.getPointerPosition() : { x: this.stage.width() / 2, y: this.stage.height() / 2 }

      const mousePointTo = {
        x: (pointer.x - this.stage.x()) / oldScale,
        y: (pointer.y - this.stage.y()) / oldScale
      }

      const scaleBy = fromPointer ? 1.02 : 1.4
      let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy
      // Limit scale
      if (newScale > 2) {
        newScale = 2
      } else if (newScale < 0.5) {
        newScale = 0.5
      }

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale
      }

      const commitChanges = () => {
        this.stageConfig.scaleX = newScale
        this.stageConfig.scaleY = newScale
        this.stageConfig.x = newPos.x
        this.stageConfig.y = newPos.y
      }

      if (fromPointer) {
        commitChanges()
      } else {
        this.stage.to({
          x: newPos.x,
          y: newPos.y,
          scaleX: newScale,
          scaleY: newScale,
          onFinish: commitChanges
        })
      }
    },
    wheel (event) {
      this.zoom(event.evt.deltaY, true)
    },

    handleKeyDown (e) {
      if (e.key === 'Escape') {
        const wall = this.walls.find(w => w.isDrawing)
        this.$store.commit(ROOMPLAN.WALLSEGMENT.DELETE, {
          wallIndex: this.elements.indexOf(wall),
          segmentIndex: wall.segments.length - 1
        })
        this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, null)
        this.finishWall()
      }
    },

    handleMouseMove (e) {
      const point = getRelativePointerPosition(e.target.getStage())
      // try to snap point
      const corners = e.target.getStage().find('.corner')
      corners.forEach(corner => {
        if (corner === this.node) {
          return
        }
        if (Math.abs(corner.x() - point.x) < WallConstants.SNAP_OFFSET) {
          point.x = corner.x()
        }
        if (Math.abs(corner.y() - point.y) < WallConstants.SNAP_OFFSET) {
          point.y = corner.y()
        }
      })

      if (this.drawingMode === 'wall') {
        const wall = this.walls.find(w => w.isDrawing)
        if (wall) {
          const indexWall = this.elements.indexOf(wall)
          this.$store.commit(ROOMPLAN.WALLSEGMENT.UPDATE, {
            indexWall,
            indexSegment: wall.isDrawing === 'start' ? 0 : wall.segments.length - 1,
            config: point
          })
        }
      }
    },
    handleClick (e) {
      const clickedOnElement = e.target.findAncestor('.element', true)
      if (!clickedOnElement && !this.drawingMode) {
        this.$store.commit(ROOMPLAN.ELEMENTS.SELECT, null)
        return
      }

      // TODO: move all this logic to one mutation?
      // or just simply it with "DOOR.ADD" and "WINDOW.ADD" with less config here
      const point = getRelativePointerPosition(e.target.getStage())
      // try to snap point
      const corners = e.target.getStage().find('.corner')
      corners.forEach(corner => {
        if (corner === this.node) {
          return
        }
        if (Math.abs(corner.x() - point.x) < WallConstants.SNAP_OFFSET) {
          point.x = corner.x()
        }
        if (Math.abs(corner.y() - point.y) < WallConstants.SNAP_OFFSET) {
          point.y = corner.y()
        }
      })

      if (this.drawingMode === 'door') {
        this.$store.commit(ROOMPLAN.ELEMENTS.ADD, {
          thickness: WallConstants.THICKNESS,
          height: WallConstants.HEIGHT,
          type: 'door',
          segments: [
            point,
            {
              x: point.x,
              y: point.y + 100
            }
          ]
        })
        this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, null)
        return
      }
      if (this.drawingMode === 'stove') {
        this.$store.commit(ROOMPLAN.ELEMENTS.ADD, {
          type: 'stove',
          segments: [
            point
          ],
          src: './svg/Appliances/thpa-appliance-stove-top-01.svg',
          config: {
            width: 100,
            height: 100,
            rotation: 0
          }
        })

        this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, null)
        return
      }
      if (this.drawingMode === 'window') {
        this.$store.commit(ROOMPLAN.ELEMENTS.ADD, {
          thickness: WallConstants.THICKNESS,
          height: WallConstants.HEIGHT,
          type: 'window',
          segments: [
            point,
            {
              x: point.x,
              y: point.y + 100
            }
          ]
        })
        this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, null)
        return
      }
      if (this.drawingMode === 'wall') {
        const wall = this.walls.find(w => w.isDrawing)
        const clickedOnCorner = !!e.target.findAncestor('.corner')
        if (wall && clickedOnCorner) {
          this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, 'wall')
          this.$store.commit(ROOMPLAN.WALLSEGMENT.DELETE, {
            wallIndex: this.elements.indexOf(wall),
            segmentIndex: wall.isDrawing === 'start' ? 0 : wall.segments.length - 1
          })
          this.finishWall()
        } else if (wall) {
          this.$store.commit(ROOMPLAN.WALLSEGMENT.ADD, {
            indexWall: this.elements.indexOf(wall),
            indexSegment: wall.isDrawing === 'start' ? 0 : wall.segments.length - 1,
            point
          })
        } else {
          this.$store.commit(ROOMPLAN.ELEMENTS.ADD, {
            thickness: WallConstants.THICKNESS,
            height: WallConstants.HEIGHT,
            type: 'wall',
            isDrawing: true,
            segments: [
              { ...point },
              { ...point }
            ]
          })
        }
        return
      }
      if (this.drawingMode === 'box') {
        console.log(clickedOnElement)
      }
    },
    finishWall () {
      // call it in case we have unfinished wall on the canvas
      const lastWall = this.walls[this.walls.length - 1]
      if (lastWall && lastWall.isDrawing) {
        this.$store.commit(ROOMPLAN.ELEMENTS.UPDATE, {
          index: this.elements.indexOf(lastWall),
          config: {
            isDrawing: false
          }
        })
      }
    },

    // Left Toolbar
    createWall () {
      this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, 'wall')
      this.finishWall()
    },
    createDoor () {
      this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, 'door')
      this.finishWall()
    },
    createWindow () {
      this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, 'window')
      this.finishWall()
    },
    createStove () {
      this.$store.commit(ROOMPLAN.DRAWING.TOGGLE, 'stove')
      this.finishWall()
    },
    toggleCatalogue () {
      this.$root.$emit('bv::hide::popover')
      if (this.$refs.catalogue) {
        this.$refs.catalogue.$refs.panels.reset()
      }
    },

    // Right Toolbar
    zoomIn () {
      this.zoom(1)
    },
    zoomOut () {
      this.zoom(-1)
    },
    undo () {
      this.$store.commit(ROOMPLAN.HISTORY.UNDO)
    },
    redo () {
      this.$store.commit(ROOMPLAN.HISTORY.REDO)
    },

    // Drag & Drop
    dragStart (e) {
      this.dragItem = e
    },
    dropStage (e) {
      const stage = this.$refs.stage.getStage()
      stage.setPointersPositions(e)
      const pos = stage.getPointerPosition(e)
      pos.x -= this.dragItem.offsetX
      pos.y -= this.dragItem.offsetY

      console.log(this.dragItem.target.classList.value)
      switch (this.dragItem.target.classList.value) {
        case 'drag-svg':
          this.$store.commit(ROOMPLAN.ELEMENTS.ADD, {
            type: 'svg-konva',
            segments: [pos],
            config: {
              width: 100,
              height: 100
            },
            src: this.dragItem.target.src
          })
          break
        case 'drag-box':
          this.$store.commit(ROOMPLAN.ELEMENTS.ADD, {
            type: 'box',
            segments: [pos],
            config: {
              width: 100,
              height: 100,
              fill: this.dragItem.target.style.backgroundColor
            }
          })
          break
      }
    }
  },
  mounted () {
    this.fitStageIntoParentContainer()
    window.addEventListener('resize', this.fitStageIntoParentContainer)
  },
  destroyed () {
    window.removeEventListener('resize', this.fitStageIntoParentContainer)
  }
}
</script>
