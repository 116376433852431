<template>
  <div class="catalogue">
    <div v-for="i in 6" :key="i" class="drag-box" :style="colour(i)" draggable="true" @dragstart="dragStart"></div>
  </div>
</template>
<script>
const COLOURS = [
  '#007bff',
  '#6610f2',
  '#6f42c1',
  '#e83e8c',
  '#dc3545',
  '#fd7e14',
  '#ffc107',
  '#28a745',
  '#20c997',
  '#17a2b8'
]
export default {
  data () {
    return {}
  },
  methods: {
    colour (index) {
      return `background-color: ${COLOURS[index]}`
    },
    dragStart (e) {
      this.$emit('boxes-drag-start', e)
    }
  }
}
</script>
<style lang="scss">
.catalogue {
  display: flex;
  flex-wrap: wrap;
  width: 240px;
}
.drag-box {
  width: 100px;
  height: 100px;
  margin: 10px;
  cursor: grab;
}
</style>
