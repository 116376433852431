// Room plans
export const ROOMPLANSADD = 'ROOMPLANSADD'
export const ROOMPLANSUPDATE = 'ROOMPLANSUPDATE'
export const ROOMPLANSDELETE = 'ROOMPLANSDELETE'
export const ROOMPLANADD = 'ROOMPLANADD'
export const ROOMPLANUPDATE = 'ROOMPLANUPDATE'
export const ROOMPLANDELETE = 'ROOMPLANDELETE'

export const ROOMPLAN = {
  CURSOR: 'ROOMPLANCURSOR',
  CURSORS: {
    DEFAULT: 'ROOMPLANCURSORDEFAULT',
    POINTER: 'ROOMPLANCURSORPOINTER',
    GRAB: 'ROOMPLANCURSORGRAB',
    GRABBING: 'ROOMPLANCURSORGRABBING'
  },
  ZOOM: {
    IN: 'ROOMPLANZOOMIN',
    OUT: 'ROOMPLANZOOMOUT'
  },
  DRAWING: {
    TOGGLE: 'ROOMPLANTOGGLEDRAWING'
  },
  HISTORY: {
    UNDO: 'ROOMPLANUNDO',
    REDO: 'ROOMPLANREDO',
    SAVE: 'ROOMPLANSAVE'
  },
  ELEMENTS: {
    ADD: 'ROOMPLANELEMENTSADD',
    DELETE: 'ROOMPLANELEMENTSDELETE',
    UPDATE: 'ROOMPLANELEMENTSUPDATE',
    SELECT: 'ROOMPLANELEMENTSSELECT',
    SNAP: 'ROOMPLANELEMENTSSNAP'
  },
  DOORS: {
    ADD: 'ROOMPLANDOORSADD',
    DELETE: 'ROOMPLANDOORSDELETE'
  },
  WALLSEGMENT: {
    ADD: 'ROOMPLANWALLADD',
    UPDATE: 'ROOMPLANWALLUPDATE',
    DELETE: 'ROOMPLANWALLDELETE'
  },
  CATALOGUE: {
    ADD: 'CATALOGUEADD',
    UPDATE: 'CATALOGUEUPDATE',
    DELETE: 'CATALOGUEDELETE'
  }
}
