<template>
  <v-group :ref="'wall-' + indexElement" :config="{ name: 'element'}">
    <segment v-for="i in segments - 1" :key="'segment-' + indexElement + '-' + (i - 1)" :index-wall="indexElement" :index-segment="i - 1" :measuresEnabled="measuresEnabled" />
    <corner v-for="i in segments" :key="'corner-' + indexElement + '-' + (i - 1)" :index-wall="indexElement" :index-segment="i - 1" :dragStart="handleDragStart"  :dragEnd="handleDragEnd"/>
  </v-group>
</template>
<script>
import Corner from '@/views/room-planner-3/components/walls/Corner'
import Segment from '@/views/room-planner-3/components/walls/Segment'
import { mapState } from 'vuex'
export default {
  components: {
    Corner,
    Segment
  },
  props: {
    indexElement: {
      required: true,
      type: Number
    }
  },
  data () {
    return { isDragging: false }
  },
  computed: {
    ...mapState({
      segments (state) { return state.roomPlan.elements[this.indexElement].segments.length },
      measuresEnabled (state) { return this.isDragging || state.roomPlan.elements[this.indexElement].isDrawing }
    })
  },
  methods: {
    handleDragStart () {
      this.isDragging = true
    },
    handleDragEnd () {
      this.isDragging = false
    }
  }
}
</script>
