import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueKonva from 'vue-konva'
import VueModal from 'vue-js-modal/dist/index.nocss.js'

import './assets/scss/app.scss'
import 'vue-js-modal/dist/styles.css'
/*
* Konva 2D Library
*/
Vue.use(VueKonva)

/*
* Vue JS Modal
*/
Vue.use(VueModal, { dynamicDefault: { draggable: false, resizable: false } })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
