<template>
  <v-group ref="corner" :config="group" @mouseover="hover = true" @mouseout="hover = false" @dragstart="dragging = true" @dragMove="dragMove" @dragend="handleDragEnd">
    <v-rect ref="rect" :config="rect"/>
    <v-line ref="lines" v-for="i in 3" :key="'line-' + i" :config="lineConfig(i)"/>
    <v-group ref="icons" :config="iconConfig" @click="addCorner">
      <icon-add-remove :config="addConfig" />
    </v-group>
  </v-group>
</template>
<script>
import Konva from 'konva'
import { mapState } from 'vuex'
import WALLCONSTANTS from './constants'
import {
  ROOMPLAN
} from '@/store/mutation-types'

import iconAddRemove from '../icons/addRemove.vue'
// import { cloneDeep } from 'lodash'

export default {
  props: {
    indexWall: {
      required: true,
      type: Number
    },
    indexSegment: {
      required: true,
      type: Number
    },
    dragStart: {
      required: true,
      type: Function
    },
    dragEnd: {
      required: true,
      type: Function
    }
  },
  components: {
    iconAddRemove
  },
  data () {
    return {
      hover: false,
      dragging: false,
      rect: {
        width: WALLCONSTANTS.THICKNESS,
        height: WALLCONSTANTS.THICKNESS,
        x: 0,
        y: 0,
        stroke: WALLCONSTANTS.COLOURS.PINK,
        strokeWidth: WALLCONSTANTS.STROKEWIDTH / 10,
        cornerRadius: WALLCONSTANTS.BORDERRADIUS,
        fill: WALLCONSTANTS.COLOURS.WHITE,
        offsetX: WALLCONSTANTS.THICKNESS / 2,
        offsetY: WALLCONSTANTS.THICKNESS / 2
      },
      anim: null
    }
  },
  computed: {
    ...mapState({
      wall (state) { return state.roomPlan.elements[this.indexWall] },
      segment (state) { return this.wall.segments[this.indexSegment] },
      isEdge () { return this.isLast || this.isFirst },
      isFirst (state) { return this.indexSegment === 0 },
      isLast (state) { return this.wall.segments.length - 1 === this.indexSegment },
      isDrawingWall (state) { return state.roomPlan.drawingMode === 'wall' }
    }),
    group () {
      return {
        // opacity: 0.5,
        name: 'corner',
        x: this.segment.x,
        y: this.segment.y,
        draggable: true,
        listening: !(this.wall.isDrawing && this.isEdge)
      }
    },
    node () {
      return this.$refs.corner.getNode()
    },
    iconConfig () {
      return {
        visible: this.isDrawingWall && this.isEdge && !this.wall.isDrawing
      }
    },
    addConfig () {
      return {
        size: 30,
        stroke: WALLCONSTANTS.COLOURS.PINK,
        fill: '#ffffff',
        add: true
      }
    }
  },
  methods: {
    lineConfig (i) {
      return {
        width: WALLCONSTANTS.THICKNESS * 0.2,
        height: WALLCONSTANTS.THICKNESS * 0.2,
        x: WALLCONSTANTS.THICKNESS * 0.2,
        y: (WALLCONSTANTS.THICKNESS * 0.25) + (WALLCONSTANTS.THICKNESS * 0.5 / 2) * (i - 1),
        stroke: WALLCONSTANTS.COLOURS.PINK,
        strokeWidth: WALLCONSTANTS.STROKEWIDTH / 10,
        points: [0, 0, WALLCONSTANTS.THICKNESS * 0.6, 0],
        lineCap: 'round',
        offsetX: WALLCONSTANTS.THICKNESS / 2,
        offsetY: WALLCONSTANTS.THICKNESS / 2
      }
    },
    dragMove () {
      const position = this.node.getPosition()
      const corners = this.node.getStage().find('.corner')
      corners.forEach(corner => {
        if (corner === this.node) {
          return
        }
        if (Math.abs(corner.x() - position.x) < WALLCONSTANTS.SNAP_OFFSET) {
          position.x = corner.x()
        }
        if (Math.abs(corner.y() - position.y) < WALLCONSTANTS.SNAP_OFFSET) {
          position.y = corner.y()
        }
      })
      // reset position manually
      this.node.position(position)
      // update state
      this.$store.commit(ROOMPLAN.WALLSEGMENT.UPDATE, {
        config: {
          ...this.config,
          x: position.x,
          y: position.y
        },
        indexWall: this.indexWall,
        indexSegment: this.indexSegment
      })
    },
    handleDragEnd () {
      this.dragging = false
      this.$store.commit(ROOMPLAN.HISTORY.SAVE)
    },
    addCorner (e) {
      e.cancelBubble = true
      const pos = e.currentTarget.getAbsolutePosition(e.currentTarget.getStage())
      // const wall = cloneDeep(this.wall)
      const segments = [...this.wall.segments]
      if (this.isFirst) {
        segments.unshift(pos)
      } else {
        segments.push(pos)
      }
      this.$store.commit(ROOMPLAN.ELEMENTS.UPDATE, { index: this.indexWall, config: { isDrawing: this.isFirst ? 'start' : 'end', segments } })
    }
  },
  watch: {
    hover (val) {
      const rect = this.$refs.rect.getNode()
      const lines = this.$refs.lines
      const anim = {
        node: rect,
        duration: WALLCONSTANTS.HOVERDURATION,
        easing: Konva.Easings.EaseInOut,
        stroke: WALLCONSTANTS.COLOURS.PINK
      }
      let cursor = ROOMPLAN.CURSORS.DEFAULT
      if (val) {
        cursor = ROOMPLAN.CURSORS.GRAB
        anim.stroke = WALLCONSTANTS.COLOURS.GREY
      }

      this.$store.commit(cursor)
      const tween = new Konva.Tween(anim)
      tween.play()

      lines.forEach(line => {
        anim.node = line.getNode()
        const tween = new Konva.Tween(anim)
        tween.play()
      })
    },
    dragging (val) {
      let cursor = ROOMPLAN.CURSORS.GRAB
      if (val) {
        cursor = ROOMPLAN.CURSORS.GRABBING
      }
      if (val) {
        this.dragStart()
      } else {
        this.dragEnd()
      }
      this.$store.commit(cursor)
    }
  }
}
</script>
